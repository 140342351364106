@import url('https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap');

:root {
    --bg-body-color: black;
    --primary-bg-color: darkblue;
    --secondary-bg-color: lightblue;
    --primary-text: white;
    --primary-font-family: "Verdana";
    --logo-font-family: "Permanent Marker";
}

html, body {
    margin: 0; 
    padding: 0;
    font-family: var(--primary-font-family);
    background-color: var(--bg-body-color);
    color: var(--primary-text);
}

h1, h2 {
    font-family: var(--logo-font-family);
}
h1 {
    font-size: 2.5em;
}
h2 {
    font-size: 1.15em;
}

.site-footing {
    color:white;
    padding-bottom: 2px;
    height: 75px;
    width: 100%;
    background-color: var(--primary-bg-color);
    border-top-right-radius: 45%;
    border-top-left-radius: 45%;
    border-top-color: var(--secondary-bg-color);
    border-top-width: 6px;
    border-top-style: solid;
    margin-top: 12px;
    position: fixed;
    bottom: 0;
}
.site-footing-logo {
    width: 100%;
    text-align: center;
    font-family: var(--logo-font-family);
    font-size: 3.25em;
    position: fixed;
    bottom: 52px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: blue;
}
.navvy {
    width: 100%;
    text-align: center;
    margin-top: 15px
}
a.navvy-pill {
    text-align: center;
    display: inline-block;
    padding: 10px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 30%;
    color: var(--secondary-bg-color);
    background-color: var(--primary-bg-color);
    text-decoration: none;
}
a.navvy-pill:hover, .navvy-pill.active {
    color: var(--secondary-bg-color);
    background-color: var(--primary-bg-color);
    filter: brightness(.55);
}
.container {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 125px;
    width: 80%;
}